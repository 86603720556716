/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const productionTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'action',
      label: '操作',
    },
    {
      key: 'id',
      thStyle: {
        width: '80px',
      },
    },
    {
      key: 'title',
      label: '产品名称',
    },
    // {
    //   key: 'pro_code',
    //   label: '产品代码',
    // },
    {
      key: 'type',
      label: '产品大类',
    },
    {
      key: 'brand',
      label: '所属公司',
    },
    {
      key: 'cate',
      label: '产品类型',
    },
    {
      key: 'rate',
      label: '派发率(%)',
    },
    // {
    //   key: 'except_rate',
    //   label: '被除率',
    // },
    {
      key: 'renew_rate',
      label: '续费派发率(%)',
    },
    {
      key: 'over_rate',
      label: '超额派发率(%)',
    },
    {
      key: 'created_at',
      label: '创建时间',
    },

  ] // table Columns
  const perPage = ref(100) // pageSize
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // nowpage
  const perPageOptions = [10, 25, 50, 100] // pageSize array
  const searchQuery = ref('') // searchTitle
  const bigType = ref(1)
  const cate_id = ref('')
  const brand_id = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = productionTable.value ? productionTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    productionTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, bigType, cate_id, brand_id], () => {
    refetchData()
  })

  const productionDelete = val => {
    store.dispatch('list-module/productionDelete', { id: val }).then(response => {
      if (response.code == 0) {
        toast(response.msg || '删除成功')
        refetchData()
      } else {
        toast(response.msg || '删除失败')
      }
    })
  }

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('list-module/fetchDataList', {
        page: currentPage.value,
        title: searchQuery.value,
        pageSize: perPage.value,
        type: bigType.value,
        cate_id: cate_id.value,
        brand_id: brand_id.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {})
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    productionTable,
    productionDelete,
    bigType,
    cate_id,
    brand_id,

    refetchData,
  }
}
