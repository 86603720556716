<!-- @format -->

<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'product_list_create' }"> 添加 </b-button>
        </b-col>

        <!-- Search -->
        <b-col md="8">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="请输入产品名称" style="width: max-content" />
            <v-select v-model="bigType" :options="statusOptions" class="invoice-filter-select" placeholder="大类筛选" :reduce="item => item.value" />
            <vSelect style="min-width: 260px; margin: auto 16px" v-model="brand_id" placeholder="请选择公司" label="title" :reduce="item => item.id" :filterable="false" :options="productList">
              <li slot="list-footer" class="pagination_vselest">
                <b-button :disabled="!hasPrevPage" @click.prevent="pages--">上一页</b-button>
                <b-button :disabled="!hasNextPage" @click.prevent="pages++">下一页</b-button>
              </li>
            </vSelect>
            <!--     类型       -->
            <vSelect style="min-width: 260px" v-model="cate_id" placeholder="请选择类型" label="title" :reduce="item => item.id" :filterable="false" :options="classList" :options-value="'value'">
              <li slot="list-footer" class="pagination_vselest">
                <b-button :disabled="!hasPrevPageClass" @click.prevent="pages--">上一页</b-button>
                <b-button :disabled="!hasNextPageClass" @click.prevent="pages++">下一页</b-button>
              </li>
            </vSelect>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table responsive :items="fetchInvoices" :fields="tableColumns" bordered ref="productionTable" small>
      <template #cell(ICON)="data" class="text-center">
        <div class="text-center">
          <feather-icon :icon="data.value" />
        </div>
      </template>
      <template v-slot:cell(type)="data">
        <div v-if="data.value == 1">人寿</div>
        <div v-else>年金</div>
      </template>
      <template v-slot:cell(brand)="data">
        <div>{{ data.value.title }}</div>
      </template>
      <template v-slot:cell(cate)="data">
        <div>{{ data.value.title }}</div>
      </template>

      <template #cell(action)="data">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret class="position-static">
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>
          <b-dropdown-item @click="setItem(data)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>编辑</span>
          </b-dropdown-item>
          <b-dropdown-item @click="productionDelete(data.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>删除</span>
          </b-dropdown-item>
        </b-dropdown>
        <!--        <b-button variant="primary" size="sm" :to="{ name: 'product_list_edit' }" @click="$store.commit('list-module/setEditData', data.item)">编辑</b-button>-->
        <!--        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>-->
        <!--          <template v-slot:button-content>-->
        <!--            <b-button variant="danger" size="sm">删除</b-button>-->
        <!--          </template>-->
        <!--          <b-dropdown-item>-->
        <!--            <feather-icon icon="TrashIcon" class="mr-50" />-->
        <!--            <span @click="productionDelete(data.item.id)">确认删除</span>-->
        <!--          </b-dropdown-item>-->
        <!--        </b-dropdown>-->
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { getCurrentInstance, onMounted, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import mix from '@/views/GeneralManagement/ProductManager/mix'
import router from '@/router'
import useInvoicesList from './Chanpin'

import ListManagerModule from './ListManagerModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,

    vSelect,
  },
  mixins: [mix],
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'list-module'
    const { proxy } = getCurrentInstance()

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ListManagerModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const statusOptions = [
      {
        label: '全部',
        value: '',
      },
      {
        label: '人寿',
        value: 1,
      },
      {
        label: '年金',
        value: 2,
      },
    ]
    onMounted(() => {
      Promise.all([proxy.getProductList(), proxy.getClassList()])
    })

    const { fetchInvoices, tableColumns, perPage, currentPage, totalInvoices, dataMeta, perPageOptions, searchQuery, productionTable, bigType, refetchData, productionDelete, cate_id, brand_id } = useInvoicesList()

    const setItem = data => {
      store.commit('list-module/setEditData', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'product_list_edit',
          query: {
            id: data.item.id,
          },
        })
      }, 500)
    }

    return {
      setItem,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      productionTable,
      bigType,
      refetchData,
      statusOptions,
      avatarText,
      productionDelete,

      cate_id,
      brand_id,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
